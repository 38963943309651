<template>
  <v-dialog :value="showDialog" transition="dialog-bottom-transition" max-width="600">
    <v-card min-height="275">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title class="pt-0 mt-0">
        <h2 class="mx-auto mb-0">{{ $t('custom.input.title') }}</h2>
      </v-card-title>

      <v-card-text class="pa-5">
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-6">
            <v-text-field
              :value="selectedCustomInput.workspaceCustomInputName"
              id="newCustomInputName"
              name="newCustomInputName"
              list="customInputName"
              :placeholder="$t('general.title')"
              :label="$t('general.title')"
              hide-details
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-6">
            <v-text-field
              v-model="customInputValue"
              id="new-custom-input-value"
              :placeholder="$t('general.value')"
              :label="$t('general.value')"
              hide-details
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="mt-0 pt-0">
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-6" order="6" order-md="0">
            <v-btn block @click="showDialog = false">
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col class="col-12 col-md-6">
            <v-btn class="primary" :loading="isSavingData" block @click="onAddCustomInputValue">
              {{ $t('general.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import { useContactCustomInputs } from '@/features/contacts/stores/customInputs';

export default defineComponent({
  name: 'EditContactCustomInputValue',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedCustomInput: {
      type: Object,
      default: null,
    },
    contactId: {
      type: [Number, String],
      required: true,
    },
    workspaceId: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const contactCustomInputsStore = useContactCustomInputs();

    return {
      contactCustomInputsStore,
    };
  },
  data() {
    return {
      customInputValue: '',
      savingData: false,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isSavingData() {
      return this.savingData;
    },
  },
  mounted() {
    this.customInputValue = this.selectedCustomInput?.value;
  },
  methods: {
    onAddCustomInputValue() {
      this.contactCustomInputsStore
        .updateCustomInputValue({
          workspaceId: this.workspaceId,
          customInputId: this.selectedCustomInput?.workspaceCustomInputId,
          customInputPayload: {
            value: this.customInputValue,
            contactId: this.contactId,
          },
        })
        .finally(() => {
          this.savingData = false;
          this.showDialog = false;
          this.$emit('on-reload');
        });
    },
  },
});
</script>

<style lang="scss" scoped></style>
